<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h1 class="display-4">Incidents {{ actionString }}</h1>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="container">
					<div class="row">
						<div class="col-lg-5 col-md-12">
							<base-input
								label="Date"
								input-classes="form-control"
								type="date"
								v-model="itemData.added_date"
								:disabled="viewOnly"
							/>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<base-input
								label="Name/Tag of Incident"
								input-classes="form-control"
								v-model="itemData.incident_name"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col form-group">
							<label class="form-control-label">Section</label>
							<select
								class="rounded m-0 form-control"
								v-model="itemData.section"
								:disabled="viewOnly"
							>
							<option  disabled :value="itemData.section">{{itemData.section}}</option>
								<option
									v-for="(type, index) in $store.state
										.farmerData.incidentsData
										.sectionList"
									:key="index"
								>
									{{ type }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col form-group">
							<label class="form-control-label"
								>Description</label
							>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets()"
								v-model="itemData.details"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
					<div class="button-bar text-right mt-4">
						<button
							class="btn btn-danger"
							@click.prevent="goBackToParent"
							v-if="!viewOnly"
						>
							Cancel
						</button>
						<button
							class="btn btn-success"
							@click.prevent="performAction"
						>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "add-incident",
	props: {
		farmerID: {
			type: String,
			default: undefined
		},
		editItem: {
			type: Object,
			default: undefined
		},
		viewOnly: {
			type: Boolean,
			default: false
		},
		selectedLandID: {
			type: Number,
		},
		selectedCrop: {
			type: Number,
		}
	},
	data() {
		return {
			itemData: {
				added_date: "",
				incident_name: "",
				section: "",
				details: "• ",
				land_id: null,
				crop_id:null
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}
				this.itemData.section = this.itemData.section.toLowerCase();

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					incidentData: this.itemData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						incidentData: this.itemData,
						index: this.editItem.index
					};
				}
				this.itemData.land_id=this.selectedLandID;
				this.itemData.crop_id=this.selectedCrop;
				this.$store
					.dispatch("setIncidentDetails", {
						farmerID: this.farmerID,
						actionObject: actionObject
					})
					.then(() => {
						this.goBackToParent();
					})
					.catch(error => {
						this.$notify({
							title:
								"Failed to " + actionString + " incident",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
		},
		inputHasErrors() {
			var invalidValue = false;
			if (this.itemData.added_date == "") {
				invalidValue = true;
			}if (this.itemData.added_date.length>10) {
				invalidValue = true;
			}
			if (this.itemData.incident_name == "") {
				invalidValue = true;
			}
			if (this.itemData.section == "") {
				invalidValue = true;
			}
			if (this.itemData.details == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.description;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.description += "• ";
			}
		},
		goBackToParent() {
			this.$router.back();
		},
		fetchIncidentDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchIncidentDetails", {
					farmerID: this.farmerID,
					itemID: this.editItem.data.id
				})
				.then(() => {
					this.displayLoader = false;
					this.itemData = JSON.parse(
						JSON.stringify(this.currentItem)
					);
					this.itemData.section = this.itemData.section.toLowerCase();
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch incident details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
	},

	computed: {
		tableData() {
			return this.$store.state.farmerData.incidentsData.tableData;
		},
		currentItem() {
			return this.tableData.find(
				item => item.id == this.editItem.data.id
			);
		},
		actionString() {
			if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},
	watch:{
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
				}
	},
	mounted() {
		if (this.selectedCrop === undefined || this.selectedLandID === undefined) {
			// Lost crop and land info; go back to restore them
			this.goBackToParent();
			return;
		}
		if (this.editItem) {
			this.fetchIncidentDetails();
			this.itemData = this.currentItem;
		}
	}
};
</script>
<style lang="scss" scoped></style>
