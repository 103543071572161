<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h1 class="display-4">
							Cost Optimization {{ actionString }}
						</h1>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="container">
					<div class="row">
						<div class="col-lg-5 col-md-12">
							<base-input
								label="Date"
								input-classes="form-control"
								type="date"
								v-model="itemData.added_date"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-5 col-md-12">
							<base-input
								label="Recommendation"
								input-classes="form-control"
								type="text"
								v-model="itemData.recommendation"
								:disabled="viewOnly"
							/>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<base-input
								label="Cost"
								input-classes="form-control"
								type="number"
								v-model="itemData.cost"
								:disabled="viewOnly"
							/>
						</div>
					</div>

					<div class="row">
						<div class="col form-group">
							<label class="form-control-label"
								>Cost Category</label
							>
							<select
								class="rounded m-0 form-control"
								v-model="itemData.category"
								:disabled="viewOnly"
							>
								<option
									v-for="(category, index) in costCategories"
									:key="index" :value="category.id"
								>
									{{ category.name }}
								</option>
							</select>
						</div>
					</div>


					<div class="row">
						<div class="col form-group">
							<label class="form-control-label">Details</label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets()"
								v-model="itemData.details"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
					<div class="button-bar text-right mt-4">
						<button
							class="btn btn-danger"
							@click.prevent="goBackToParent"
							v-if="!viewOnly"
						>
							Cancel
						</button>
						<button
							class="btn btn-success"
							@click.prevent="performAction"
						>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "add-cost-optimization",
	props: {
		farmerID: {
			type: String,
			default: undefined
		},
		editItem: {
			type: Object,
			default: undefined
		},
		viewOnly: {
			type: Boolean,
			default: false
		},
		selectedLandID: {
			type: Number
		},
		selectedCrop: {
			type: Number
		}
	},
	data() {
		return {
			itemData: {
				land: this.selectedLandID,
				added_date: "",
				cost: "",
				recommendation: "",
				details: "• "
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					costOptimizeData: this.itemData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						costOptimizeData: this.itemData,
						index: this.editItem.index
					};
				}

				this.$store
					.dispatch("setCostOptimizeDetails", {
						actionObject: actionObject,
						farmerID: this.farmerID
					})
					.then(() => {
						this.goBackToParent();
					})
					.catch(error => {
						this.$notify({
							title: "Failed to " + actionString + " cost",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
		},
		inputHasErrors() {
			var invalidValue = false;
			if (this.itemData.added_date == "") {
				invalidValue = true;
			}if (this.itemData.added_date.length>10) {
				invalidValue = true;
			}
			if (this.itemData.cost == "") {
				invalidValue = true;
			}
			if (this.itemData.recommendation == "") {
				invalidValue = true;
			}
			if (this.itemData.details == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.details;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.description += "• ";
			}
		},
		goBackToParent() {
			this.$router.back();
		},
		fetchCostOptimizeDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchCostOptimizeDetails", {
					farmerID: this.farmerID,
					itemID: this.editItem.data.id
				})
				.then(() => {
					this.displayLoader = false;
					this.itemData = JSON.parse(
						JSON.stringify(this.currentItem)
					);
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch cost optimized detail",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		},
		fetchCostCategoriesList() {
			this.$store
				.dispatch("fetchCostCategoriesList","")
				.then(() => {
					this.displayLoader = false;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch cost categories",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
	},

	computed: {
		tableData() {
			return this.$store.state.farmerData.expenditureData.tables[1].data;
		},
		costCategories() {
			return this.$store.state.farmerData.expenditureData.costCategories;
		},
		currentItem() {
			return this.tableData.find(
				item => item.id == this.editItem.data.id
			);
		},
		actionString() {
			if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},watch:{
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
				}
	},
	mounted() {
			if (this.selectedCrop === undefined || this.selectedLandID === undefined) {
			// Lost crop and land info; go back to restore them
			this.goBackToParent();
			return;
		}
		if (this.editItem) {
			this.fetchCostOptimizeDetails();
			this.itemData = this.currentItem;
		}
		this.fetchCostCategoriesList();
	}
};
</script>
<style lang="scss" scoped></style>
