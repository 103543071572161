<template>
	<div>
		<div class="row">
			<div class="col">
				<div slot="header">
					<div class="row text-center">
						<div class="col-12">
							<h1>Nutrient - {{ actionString }}</h1>
						</div>
					</div>
				</div>
				<div class="pl-lg-4">
					<div class="row">
						<div class="col-12">
							<base-input
								alternative=""
								label="Name of the Nutrient"
								input-classes="form-control"
								v-model="itemData.nutrient_name"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-12 form-group">
							<label class="form-control-label">Properties</label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets()"
								v-model="itemData.properties"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-6">
							<!-- <label class="form-control-label">Sources</label>
              <select
                class="rounded m-0 form-control"
                v-model="itemData.sourceSelected"
                :disabled="viewOnly"
              >
                <option>One</option>
                <option>Two</option>
                <option>Three</option>
              </select> -->
							<base-input
								label="Source"
								input-classes="form-control"
								placeholder="Nutrient source"
								v-model="itemData.source"
								:disabled="viewOnly"
							/>
						</div>
						<div class="col-lg-6">
							<base-input
								label="Source details"
								input-classes="form-control"
								placeholder="Details of source and cost"
								v-model="itemData.source_details"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-4">
							<base-input
								label="Disease Deficiency"
								input-classes="form-control"
								placeholder="Deficiency"
								v-model="itemData.dieseas_deficiency"
								:disabled="viewOnly"
							/>
						</div>
						<div class="col-lg-4">
							<base-input
								label="Disease Poison"
								input-classes="form-control"
								placeholder="Poison"
								v-model="itemData.dieseas_poison"
								:disabled="viewOnly"
							/>
						</div>
						<div class="col-lg-4 form-group">
							<base-input
								label="Disease Other"
								input-classes="form-control"
								placeholder="Other"
								v-model="itemData.dieseas_other"
								:disabled="viewOnly"
							/>
						</div>
					</div>
				</div>

				<div class="button-bar text-right mt-4">
					<button
						class="btn btn-danger"
						@click.prevent="goBackToParent"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="performAction"
					>
						Done
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "add-nutrients",
	props: {
		selectedCrop: Number,
		editItem: {
			type: Object,
			default: undefined
		},
		viewOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			select: "Select",
			itemData: {
				crop: "",
				nutrient_name: "",
				properties: "• ",
				source: "One",
				source_details: "",
				dieseas_deficiency: "",
				dieseas_poison: "",
				dieseas_other: ""
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}

				// if (this.editItem == undefined) {
				//   this.tableData.unshift(this.itemData);
				// } else {
				//   this.tableData.splice(this.editItem.index, 1, this.itemData);
				// }

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					nutrientData: this.itemData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						nutrientData: this.itemData,
						index: this.editItem.index
					};
				}

				this.$store
					.dispatch("setNutrientsDetails", actionObject)
					.then(() => {
						this.goBackToParent();
					})
					.catch(error => {
						this.$notify({
							title: "Failed to " + actionString + " nutrient",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			}
		},
		inputHasErrors() {
			var invalidValue = false;

			if (this.itemData.nutrientName == "") {
				invalidValue = true;
			}
			if (this.itemData.details == "") {
				invalidValue = true;
			}
			if (this.itemData.sourceDetails == "") {
				invalidValue = true;
			}
			if (this.itemData.diseaseDeficiency == "") {
				invalidValue = true;
			}
			if (this.itemData.diseasePoison == "") {
				invalidValue = true;
			}
			if (this.itemData.diseaseOther == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.details;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.details += "• ";
			}
		},
		goBackToParent() {
			this.$router.back();
		},
		selectCrop() {
			this.itemData.crop = this.selectedCrop
		}
	},
	computed: {
		tableData() {
			return this.$store.state.cropData.nutrients.tableData;
		},
    currentNutrient() {
      return this.tableData.find(item=>item.id == this.editItem.data.id)
    },
		actionString() {
			if (this.viewOnly) {
				return "View";
			} else if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},
	watch: {
		selectedCrop() {
			this.selectCrop();
		}
	},
	mounted() {
		if(this.selectedCrop === undefined){
			this.$router.back()
		}
		if (this.editItem) {
			// this.itemData = this.editItem.data;
			this.$store
				.dispatch("fetchNutrientsDetails", {nutrientID:this.editItem.data.id})
				.then(() => {
					this.itemData = this.currentNutrient
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch nutrient details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		}
		this.selectCrop();
	}
};
</script>
<style scoped></style>
