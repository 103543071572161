<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h1 class="display-4">
							{{ actionString }} Productivity
						</h1>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="container">
					<div class="row">
						<div class="col">
							<base-input
								label="Name"
								input-classes="form-control"
								v-model="itemData.name"
								:disabled="viewOnly"
							/>
						</div>
					</div>

					<div class="row">
						<div class="col-6">
							<base-input
								label="Start date"
								input-classes="form-control"
								type="date"
								v-model="itemData.startDate"
								:disabled="viewOnly"
							/>
						</div>
						<div class="col-6">
							<base-input
								label="End date"
								input-classes="form-control"
								type="date"
								v-model="itemData.endDate"
								:disabled="viewOnly"
							/>
						</div>
					</div>

					<div class="row">
						<div class="col form-group">
							<label class="form-control-label">Details</label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets()"
								v-model="itemData.content"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
					<div class="button-bar text-right mt-4">
						<button
							class="btn btn-danger"
							@click.prevent="goBackToParent"
							v-if="!viewOnly"
						>
							Cancel
						</button>
						<button
							class="btn btn-success"
							@click.prevent="performAction"
						>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "add-productivity",
	props: {
		farmerID: {
			type: String,
			default: undefined
		},
		crop: {
			type: Number,
			default: undefined,
		},
		landID: {
			type: Number,
		},
		editItem: {
			type: Object,
			default: undefined,
		},
		viewOnly: {
			type: Boolean,
			default: false,
		},
		selectedLandID: {
			type: Number,
		},
		selectedCrop: {
			type: Number,
		}
	},
	data() {
		return {
			itemData: {
				farmer: this.farmerID,
				date: "",
				name: "",
				startDate: "",
				endDate: "",
				content: "• ",
				land_id:null,
				crop_id:null
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					productivityData: this.itemData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						productivityData: this.itemData,
						index: this.editItem.index
					};
				}
				this.itemData.land_id=this.selectedLandID;
				this.itemData.crop_id=this.selectedCrop;

				this.$store
					.dispatch("setProductivity", {
						farmerID: this.farmerID,
						actionObject,
					})
					.then(() => {
						this.goBackToParent();
					})
					.catch(error => {
						this.$notify({
							title:
								"Failed to " + actionString + " productivity",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
		},
		inputHasErrors() {
			var invalidValue = false;
			if (this.itemData.startDate == "") {
				invalidValue = true;
			}if (this.itemData.startDate.length>10) {
				invalidValue = true;
			}
			if (this.itemData.endDate == "") {
				invalidValue = true;
			}if (this.itemData.endDate.length>10) {
				invalidValue = true;
			}
			if (this.itemData.name == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields"
				});
			}

			return invalidValue;
		},
		watchBullets() {
			// Fragile lol
			var str = this.itemData.content;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.content += "• ";
			}
		},
		goBackToParent() {
			this.$router.back();
		},
		fetchProductivityDetails() {
			this.displayLoader = true;
			this.$store
				.dispatch("fetchProductivityDetails", {
					farmerID: this.farmerID,
					itemID: this.editItem.data.id
				})
				.then(() => {
					this.displayLoader = false;
					this.itemData = JSON.parse(
						JSON.stringify(this.currentItem)
					);
					this.itemData.land = this.landID;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch productivity details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
					this.displayLoader = false;
				});
		}
	},

	computed: {
		tableData() {
			return this.$store.state.farmerData.productivityData.tableData.data;
		},
		currentItem() {
			return this.tableData.find(
				item => item.id == this.editItem.data.id
			);
		},
		actionString() {
			if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},
	watch:{
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
				}
	},
	mounted() {
		if (this.crop === undefined || this.landID === undefined) {
			// Lost crop and land info; go back to restore them
			this.goBackToParent();
			return;
		}

		if (this.editItem) {
			this.fetchProductivityDetails();
			this.itemData = this.currentItem;
		}

		this.itemData.land = this.landID;
	}
};
</script>
<style lang="scss" scoped></style>
