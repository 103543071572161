<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h1 class="display-4">
							{{ actionString }} Agri Inputs
						</h1>
					</div>
				</div>
			</div>
			<div class="p-4">
				<div class="row">
					<div class="col-lg-5 col-md-12">
						<base-input
							label="Date"
							input-classes="form-control"
							type="date"
							v-model="itemData.date"
							:disabled="viewOnly"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<label class="form-control-label"
							>Agri Input Name</label
						>
						<base-input
							input-classes="form-control"
							v-model="itemData.name"
							:disabled="viewOnly"
						/>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-group">
						<label class="form-control-label">Input Type</label>
						<select
							class="rounded m-0 form-control"
							v-model="itemData.type"
							:disabled="viewOnly"
						>
							<option
								v-for="(type, index) in typeList"
								:key="index"
								:value="type"
							>
								{{ type }}
							</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-12 form-group">
						<label class="form-control-label">Details:</label>
						<textarea
							class="form-control"
							rows="8"
							@input="watchBullets()"
							v-model="itemData.details"
							:disabled="viewOnly"
						></textarea>
					</div>
				</div>
				<div class="button-bar text-right mt-4">
					<button
						class="btn btn-danger"
						@click.prevent="goBackToParent"
						v-if="!viewOnly"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="performAction"
					>
						Done
					</button>
				</div>
			</div>
			<loader :displayLoader="displayLoader"></loader>
		</div>
	</div>
</template>

<script>
export default {
	name: "add-agri-inputs",
	props: {
		farmerID: {
			type: String,
			default: undefined,
		},
		selectedLandID:{
			type:Number
		},
		crop: {
			type: Number,
			default: undefined,
		},
		landID: {
			type: Number,
		},
		editItem: {
			type: Object,
			default: undefined,
		},
		viewOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			displayLoader: false,
			itemData: {
				date: "",
				name: "",
				type: "",
				details: "• ",
				crop_id:null,
				land_id:null
			},
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				if (this.inputHasErrors()) {
					return;
				}

				this.displayLoader = true;
				this.itemData.crop_id=this.crop;
				this.itemData.land_id=this.landID
				this.$store.dispatch("updateAgriInput", { farmerID: this.farmerID, agriInput: this.itemData })
					.then(() => {
						this.goBackToParent();
					}).catch((error) => {
						this.$notify({
							title: "Failed to " + this.actionString.toLowerCase() + " agri input",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					}).then(() => {
						this.displayLoader = false;
					});
			} else {
				this.goBackToParent();
			}
		},
		watchBullets() {
			var str = this.itemData.details;
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData.details += "• ";
			}
		},
		inputHasErrors() {
			var invalidValue = false;
			if (this.itemData.date == "") {
				invalidValue = true;
			}if (this.itemData.date.length>10) {
				invalidValue = true;
			}
			if (this.itemData.name == "") {
				invalidValue = true;
			}
			if (this.itemData.type == "") {
				invalidValue = true;
			}
			if (this.itemData.details == "") {
				invalidValue = true;
			}

			if (invalidValue) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: "Please enter valid data for all fields",
				});
			}

			return invalidValue;
		},

		goBackToParent() {
			this.$router.back();
		},
	},

	computed: {
		tableData() {
			return this.$store.state.farmerData.agriInputData.agriInputList;
		},
		typeList() {
			return this.$store.state.farmerData.agriInputData.types;
		},
		actionString() {
			if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		},
	},watch:{
		selectedLandID(){
		this.$store.dispatch("fetchFarmerCrops",this.selectedLandID)
		.catch(error => {
					this.$notify({
						title: "Failed to fetch crop",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				});	
				}
	},
	mounted() {
		if (this.crop === undefined || this.landID === undefined) {
			// Lost crop and land info; go back to restore them
			this.goBackToParent();
			return;
		}

		if (this.editItem) {
			this.itemData = this.editItem.data;
		}

	},
};
</script>
<style lang="scss" scoped></style>
