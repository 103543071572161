<template>
	<div>
		<div :class="screen >=1300 ? 'container-fluid' :'container' ">
			<div class="row">
				<div class="col">
					<h1 class="display-2">Products</h1>
				</div>
			</div>
			<div class="row mt-4">
				<div
					class="col-lg-5 col-md-6 col-sm-6 d-flex align-items-center justify-content-between"
				>
					<!-- <base-input
						placeholder="Search"
						class="input-group-alternative"
						addon-right-icon="fas fa-search"
					>
					</base-input> -->
				</div>
				<div class="col text-right">
					<base-button type="button" @click.prevent="addProduct" class="btn btn-success">
						Add Products
					</base-button>
					<base-button type="button" @click.prevent="addProductImage" class="btn btn-outline-warning">
						Add Product Image
					</base-button>
				</div>
			</div>
			<div class="row mt-4">
				<div
                class=""
					:class= " screen >=1300 ? 'col-lg-2 col-md-4 col-sm-6  pt-3':'col-lg-3 col-md-4 col-sm-6  pt-3' "
					v-for="(product, index) in productList"
					:key="index + 'page'" >
					<div class="p-0">
						<div class="card custom-card">
							<img
								class="custom-image img-fluid w-100"
								:src="productImage(product.image)"
								alt="Product Image"
								@click.prevent="handleProduct(product.id)"/>
							<div class="mt-2 mb-1 card-title px-4 custom-title">
								<h4 class="mb-1 text-muted">{{product.name}}</h4>
								<div class="d-flex justify-content-start">
									<span class="text-green">₹{{product.offer===true ?product.offer_price:product.price}}</span>
									<span v-if="product.offer===true" class="ml-2  text-muted"><s>₹{{product.price}}</s></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<modal
			class="modal"
			:show="addImageModal"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="addImageModal = false"
			modal-classes=" modal-dialog-centered  modal-md"
			type="mini"
		>
			<template v-slot:header>
				<div class="row">
					<h3 class="col-sm">Add Image</h3>
				</div>
			</template>
			<div class="mb-4">
				<select
						placeholder="Choose Product"
						name="Product"
						id="product"
						class="form-control"
						v-model="productID"
					>
						<option :value="null">Choose Product</option>
						<option
							v-for="(item, idx) in productList"
							:key="idx"
							:value="item.id"
						>
							{{ item.name }}
						</option>
					</select>
				<input
					class="form-control mt-3"
					type="file"
					@change="onFileChange"
				/>
				<p class="text-sm mt-2 ml-2 text-danger"> Dimensions: 16:9 ratio</p>
			</div>
			<template v-slot:footer>
				<base-button
					type="danger pl-4 pr-4 "
					class="mb-2 btn btn-sm"
					@click.prevent="addImageModal = false"
					>Cancel</base-button
				>
				<base-button
					type="success pl-4 pr-4"
					class="mb-2 btn btn-sm"
					@click.prevent="save"
					>Save</base-button
				>
			</template>
		</modal>
		</div>
		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
export default {
	data() {
		return {
			displayLoader: false,
			productID:null,
			addImageModal: false,
			files: [],
			imageData: {
				id: null,
				image: "",
			},
		};
    },
    computed: {
        screen() {
            return window.screen.width
        },
        productList() {
			return this.$store.state.EcommerceData.Product.productList;
		},
		
    },
	methods: {
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.imageData.image = this.files[0].name;
		},
		save() {
			var error = undefined;
			if (this.productID == null) {
				error = "Product must be selected ";
			}
			
			if (error) {
				this.$notify({
					title: error,
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}
			var formData = new FormData();
			formData.append("image", this.files[0]);
			formData.append("id", this.productID);
			this.$store
				.dispatch("uploadProductImage", {
					imageData: formData,
					productID: this.productID,
				})
				.then(() => {
					this.$notify({
							title: "Image added successfully",
							icon: "fa fa-check",
							type: "success",
						});
					this.$store.dispatch("fetchProductList").catch((error) => {
						this.displayLoader = false;
						this.$notify({
							title: "Failed to fetch product list",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to update image",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
			this.addImageModal=false
		},
        addProduct() {
            this.$router.push('products/add-product')
		},
		addProductImage() {
			this.addImageModal=true
		},
		productImage(image) {
			return `https://${image}`
		},
		handleProduct(id) {
			this.$router.push(`products/${id}/edit-product`)
			
		},
        fetchProductList(){
            this.displayLoader = true;
			this.$store
				.dispatch("fetchProductList")
                .then(() => {this.displayLoader = false;})
				.catch((error) => {
                    this.displayLoader = false;
					this.$notify({
						title: "Failed to fetch product list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
		},
    },
    mounted() {
        this.fetchProductList();
    }
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
.custom-card {
	width: 12rem;
	border: 1px solid gray;
	border-radius: 20px;
}
.custom-image {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	cursor: pointer;
	height: 12rem;
}
.custom-title {
	border-radius: 5px !important;
	color: white !important;
    font-family: 'Roboto Slab', serif;
}

</style>
