<template>
	<div>
		<div class="row">
			<div class="col">
				<div slot="header" class="border-0">
					<div class="row align-items-center">
						<div class="col">
							<h3 class="mb-0">{{ actionString }} Item</h3>
						</div>
					</div>
				</div>

				<h6 class="heading-small text-muted mb-4">Pests and Attacks</h6>
				<div class="pl-lg-4">
					<div class="row">
						<div class="col-lg-6">
							<base-input
								alternative=""
								label="Pest Name"
								input-classes="form-control"
								v-model="itemData.pest_name"
								:disabled="viewOnly"
							/>
						</div>
						<div class="col-lg-6">
							<base-input
								alternative=""
								label="Attack Name"
								input-classes="form-control"
								v-model="itemData.attack_name"
								:disabled="viewOnly"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<label class="form-control-label"> Remedy </label>
							<select class="rounded p-2 w-100 form-control mb-3"
							v-model="itemData.remedy"
							:disabled="viewOnly">
							<option selected disabled value="" >Choose</option>
							<option v-for="(item,idx) in ($store.state.cropData.pestsAndAttacks.pesticides)" :key="idx" :value="item.id">{{item.name}}</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-12 form-group">
							<label class="form-control-label">
								Details / Symptoms
							</label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets(3)"
								v-model="itemData.details_or_symptoms"
								:disabled="viewOnly"
							></textarea>
						</div>
						<div class="col-12 form-group">
							<label class="form-control-label">
								Sources / Reason
							</label>
							<textarea
								class="form-control"
								rows="8"
								@input="watchBullets(4)"
								v-model="itemData.sources_or_reasons"
								:disabled="viewOnly"
							></textarea>
						</div>
					</div>
				</div>

				<div class="button-bar text-right mt-4">
					<button
						class="btn btn-danger"
						v-if="!viewOnly"
						@click.prevent="$router.back()"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						@click.prevent="performAction"
					>
						Done
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "add-pests-and-attacks",
	props: {
		selectedCrop: Number,
		editItem: {
			type: Object,
			default: undefined
		},
		viewOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			itemData: {
				crop: "",
				pest_name: "",
				attack_name: "",
				details_or_symptoms: "",
				sources_or_reasons: "",
				remedy: null
			}
		};
	},
	methods: {
		performAction() {
			if (!this.viewOnly) {
				var invalidValue = false;

				if (this.itemData.pest_name == "") {
					invalidValue = true;
				} else if (this.itemData.attack_name == "") {
					invalidValue = true;
				} else if (this.itemData.details_or_symptoms == "") {
					invalidValue = true;
				} else if (this.itemData.nutrient_cause_type == "") {
					invalidValue = true;
				} else if (this.itemData.sources_or_reasons == "") {
					invalidValue = true;
				} else if (this.itemData.remedy < 0) {
					invalidValue = true;
				}

				if (invalidValue) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Please enter valid data for all fields"
					});
					return;
				}

				this.displayLoader = true;
				// Add new action params
				var actionString = "add";
				var actionObject = {
					itemData: this.itemData
				};

				if (this.editItem && this.editItem.index > -1) {
					// Edit existing action params
					actionString = "edit";
					actionObject = {
						itemData: this.itemData,
						index: this.editItem.index
					};
				}
				this.$store
					.dispatch("setPestsAndAttacksDetails", {
						actionObject: actionObject
					})
					.then(() => {
						this.$router.back();
					})
					.catch(error => {
						this.$notify({
							title: "Failed to " + actionString + " pest and attack",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
					})
					.then(() => {
						this.displayLoader = false;
					});
			}
		},
		watchBullets(index) {
			// Fragile lol
			var str = this.itemData[index];
			if (str[str.length - 1] == "\n" || str.length == 0) {
				this.itemData[index] += "• ";
			}
		},
		selectCrop() {
			this.itemData.crop = this.selectedCrop
		},
		fetchPesticide(){
			this.$store.dispatch("fetchPesticide",this.selectedCrop)
			.catch(error => {
						this.$notify({
							title: "Failed to fetch  pesticide",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString()
						});
			})
    }
	},
	watch: {
		selectedCrop() {
			this.selectCrop();
			this.fetchPesticide();
		}
	},
	computed: {
		table() {
			return this.$store.state.cropData.pestsAndAttacks.tableData;
		},
		currentItem() {
			return this.table.find(item => item.id == this.editItem.data.id);
		},
		actionString() {
			if (this.viewOnly) {
				return "View";
			} else if (this.editItem) {
				return "Edit";
			} else {
				return "Add";
			}
		}
	},
	mounted() {
		if(this.selectedCrop === undefined){
			this.$router.back()
		}
		if(this.selectedCrop != undefined){
			this.fetchPesticide();
		}
		if (this.editItem) {
			this.$store
				.dispatch("fetchPestsAndAttacksDetails", {
					pestsAndAttacksID: this.editItem.data.id
				})
				.then(() => {
					this.itemData = this.currentItem;
				})
				.catch(error => {
					this.$notify({
						title: "Failed to fetch pest and attack details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString()
					});
				})
				.then(() => {
					this.displayLoader = false;
				});
		}
		this.selectCrop()
	}
};
</script>
<style scoped></style>
